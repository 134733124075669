export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_EMPLOYEE = "SE",
  OEM_ADMIN = "OEMA",
  OEM_EMPLOYEE = "OEME",
  DRIVER = "DR",
  EV_OWNER = "EVO",
  FLEET_ADMIN = "FA",
  FLEET_EMPLOYEE = "FE",
  DEALER_ADMIN = "DA",
  DEALER_EMPLOYEE = "DE",
  BMS_ADMIN = "BMSA",
  BMS_EMPLOYEE = "BMSE",
  MCU_ADMIN = "MCUA",
  MCU_EMPLOYEE = "MCUE",
  VCU_ADMIN = "VCUA",
  VCU_EMPLOYEE = "VCUE",
  GPS_ADMIN = "GPSA",
  GPS_EMPLOYEE = "GPSE",
  OBC_ADMIN = "OBCA",
  OBC_EMPLOYEE = "OBCE",
  DCDC_ADMIN = "DCDCA",
  DCDC_EMPLOYEE = "DCDCE",
  BTMS_ADMIN = "BTMSA",
  BTMS_EMPLOYEE = "BTMSE",
  TPMS_ADMIN = "TPMSA",
  TPMS_EMPLOYEE = "TPMSE",
}

export enum OEM_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
}

export const oemStatus: Record<string, any> = {
  Inactive: 0,
  Active: 1,
};

export const faultComponents: Record<string, string> = {
  BMS: "BMS",
  VCU: "VCU",
  MCU: "MCU",
  EV: "EV",
};

export const faultTypes: Record<string, string> = {
  Component: "Component",
  System: "System",
};

export const faultCategories: Record<string, string> = {
  Alert: "Alert",
  Fault: "Fault",
};

export const ticketStatus: Record<string, number> = {
  Closed: 6,
  New: 1,
  "In-Progress": 2,
  Hold: 3,
  Reopen: 4,
  Deleted: 5,
};

export enum SEVERITY {
  VERY_GOOD = 1,
  GOOD = 2,
  AVERAGE = 3,
  BAD = 4,
  WORST = 5,
}

export const severityObj: Record<string, number> = {
  "Very Good": 1,
  Good: 2,
  Average: 3,
  Bad: 4,
  Worst: 5,
};

export enum RULE_TYPE {
  TELEMETRY = 1,
  SHADOW = 2,
  SYSTEM_GENERATED = 3,
  AI = 4,
}

export const ruleTypeObj: Record<string, number> = {
  Telemetry: 1,
  Shadow: 2,
  "System Generated": 3,
  AI: 4,
};

export enum RULE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export const ruleStatusObj: Record<string, number> = {
  Inactive: 0,
  Active: 1,
  Deleted: 2,
};

export enum CONDITION_TYPE {
  AND = 1,
  OR = 2,
}

export enum CONDITION_OPERATOR {
  LESS_THEN = "<",
  LESS_THEN_OR_EQUAL = "<=",
  GREATER_THEN = ">",
  GREATER_THEN_OR_EQUAL = ">=",
  EQUALS = "==",
}

export enum SHADOW_FIELDS {
  THERMAL_TYPE = "TTy",
  CHEMISTRY_TYPE = "CTy",
  CYCLE = "Cycl",
  CAPACITY = "Cap",
  CELL_CONFIGURATION = "CCfg",
  CELL_UNITS = "CUn",
  CELL_CHEMISTRY = "CChem",
  MOTER_TYPE = "MTy",
}

export enum TELEMETRY_FIELDS {
  VOLTAGE = "Volt",
  CURRENT = "BCur", // Is it BMS OR MCU Current If its BMS set value to BCur and If MCU set MCur
  SOC = "Soc",
  SOH = "Soh",
  BATTERY_TEMPRATURE = "BTemp",
  CYCLE_CNT = "CyCnt",
  State = "State",
  MINIMUM_DISCHARGE_VOLTAGE = "MinDchV",
  MAXIMUM_CHARGE_VOLTAGE = "MaxChgV",
  MCU_TEMPERATURE = "MTemp",
  EM_TEMPERATURE = "EmTemp",
  EM_RPM = "EmRpm",
  TORQUE = "Trq",
  VEHICLE_STATE = "VehSt",
  FAULT_SEVERITY = "FltSev",
  SPEED = "Spd",
  ESTIMATION_RANGE = "EstRng",
  ODOMETER = "Odo",
  TRIP_DISTANCE = "TripD",
  CURRENT_MODE = "CurMd",
  DRIVE_MODE = "DrvMd",
  WATT_HOURS_PER_KM = "WhpKm",
  DRIVE_POWER = "DrvPwr",
  REGEN_POWER = "Rgnpwr",
  ESTIMATION_WEIGHT = "EstWght",
  POWERTRAIN_EFFICIENCY = "PtEff",
}

export enum ACK_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum COMMAND_TYPES {
  DEVICE = 1,
  OTA = 2,
  SYSTEM = 3,
}

export enum COMMAND_FOR_TYPES {
  DEVICE_COMMAND = "DEVICE_COMMAND",
  RULE_COMMAND = "RULE_COMMAND",
  OTA_COMMAND = "OTA_COMMAND",
}

export enum MODEL_COMPONENT_TYPES {
  BMS = "bms",
  VCU = "vcu",
  MCU = "mcu",
  EV = "ev",
}

export enum VEHICLE_ASSIGN_VALUE_TYPE {
  DEALER = "dealer",
  FLEET = "fleet",
  EVOWNER = "evowner",
  DRIVER = "driver",
}

export enum AUDIT_CATEGORY {
  Oem = "oem",
  Employee = "employee",
  AccessManagement = "access_managment",
  RuleManagement = "rule_managment",
  Command = "command",
  VehicleManagement = "vehicle_management",
  VehicleModelManagement = "vehicle_model_management",
  Setting = "setting",
  MyProfile = "my_profile",
  BMSManagement = "bms_managment",
  MCUManagement = "mcu_managment",
  VCUManagement = "vcu_managment",
  DriverManagement = "driver_management",
  EVOwnerManagement = "ev_owner_management",
  FleetManagement = "fleet_management",
  DealerManagement = "dealer_managment",
  FaultManagement = "fault_managment",
  TicketManagement = "ticket_management",
  User = "user_management",
  Download = "download",
  ITCUProducts = "itcu_products",
  ComponentMasterData = "component_master_data",
  Model = "model",
  GEOFENCE = "geo_fencing",
  SimInventory = "sim_inventory",
  LoggerDeviceInventory = "Logger_device_inventory",
  VehicleTrip = "vehicle_trip",
  BarretyCycle = "barrety_cycle",
}

export enum AUDIT_ENTITY {
  OEM = "OEM",
  USER = "User",
  PROFILE = "Profile",
  OEM_REL = "OEM Relation",
  ACCESS = "Access",
  RULE = "Rule",
  // RULE_SUBSCRIBER = 'Rule',
  // RULE_SUBSCRIBER_ENTITY_OWNER = 'RuleSubscriberEntityOwner',
  COMMAND = "Command",
  TELEMETRY = "Telemetry",
  REDIS = "Redis",
  VEHICLE = "Vehicle",
  VEHICLE_MODEL = "Vehicle Model",
  VEHICLE_REL = "Vehicle Relation",
  SETTING = "Settings",
  BMS = "BMS",
  MCU = "MCU",
  VCU = "VCU",
  FLEET = "Fleet",
  DEALER = "Dealer",
  EV_OWNER = "EV Owner",
  DRIVER = "Driver",
  EMPLOYEE = "Employee",
  FLEET_REL = "Fleet Relation",
  DEALER_REL = "Dealer Relation",
  FAULT = "Fault",
  FEEDBACK = "Ticket",
  FEEDBACK_ASSIGNMENT = "Ticket Assignment",
  FEEDBACK_CONVERSION = "Ticket Conversation",
  VEHICLE_VCU = "Vehicle VCU",
  OTP_SESSION = "OTP Session",
  DEVICE_TOKEN = "Device Token",
  ITCU_PRODUCTS = "ITCU Products",
  COMPONENT_MASTER_DATA = "Component Master Data",
  CONFIGURATION_STEPS = "Configuration Steps",
  GEOFENCE = "Geofence",
  GEOFENCE_RULE = "Geofence Rule",
  MODEL = "Model",
  SIM = "SIM Card",
  LOGGER_DEVICE = "Logger Device",
  VEHICLE_TRIP = "Vehicle Trip",
  BARRETY_CYCLE = "Barrety Cycle",
  ANALYSIS_DATA = "Analysis Data",
}

export enum AUDIT_ACTION {
  POST = "POST",
  PATCH = "PATCH",
  CLONE = "CLONE",
  EXPORT = "EXPORT",
  RESYNC = "RESYNC",
  ASSIGN = "ASSIGN",
  UNASSIGN = "UNASSIGN",
  BULK_IMPORT = "BULK_IMPORT",
}
export enum AUDIT_ACTION_TITLE {
  POST = "Add",
  PATCH = "Update",
  CLONE = "Clone",
  EXPORT = "Export",
  RESYNC = "Resync",
  ASSIGN = "Assign",
  UNASSIGN = "Unassign",
  BULK_IMPORT = "Bulk Import",
}

export enum AUDIT_ACTION_PAST_TITLE {
  POST = "added",
  PATCH = "updated",
  CLONE = "cloned",
  EXPORT = "exported",
  RESYNC = "resynced",
  ASSIGN = "assigned",
  UNASSIGN = "unassigned",
  BULK_IMPORT = "bulk imported",
}

export enum FIRMWARE_OTA_UPDATE_STATUS {
  OTA_COMMAND_SENT = 0,
  OTA_IN_PROGRESS = 1,
  OTA_COMPLETED = 2,
  OTA_FAILED = 3,
  DEVICE_OFFLINE = 4,
}

export const otaStatus: Record<string, number> = {
  "Command Sent": 0,
  "In Progress": 1,
  Completed: 2,
  Failed: 3,
  "Device Offline": 4,
};

export enum FIRMWARE_TYPES {
  LOGGER = 0,
  BMS = 1,
  MCU = 2,
  VCU = 3,
}

export const firmwareType: Record<string, number> = {
  Logger: 0,
  BMS: 1,
  MCU: 2,
  VCU: 3,
};

export enum PERMISSION_ACTIONS {
  READ = 1,
  ADD = 2,
  UPDATE = 4,
  DELETE = 8,
  ASSIGN = 16,
  UNASSIGN = 32,
  CLONE = 64,
  EXPORT = 128,
  IMPORT = 256,
  RESYNC = 512,
  COPY_LINK = 1024,
  RESET = 2048,
  CONFIGURE = 4096,
  CHANGE_STATUS = 8192,
}

export const permissionActions: Record<string, any> = {
  List: 1,
  Add: 2,
  Update: 4,
  Delete: 8,
  Assign: 16,
  Unassign: 32,
  Clone: 64,
  Export: 128,
  Import: 256,
  Resync: 512,
  "Copy Link": 1024,
  Reset: 2048,
  Configure: 4096,
  "Change Status": 8192,
};

export enum DELIVERY_METHOD {
  PUSH_NOTIFICATION = 1,
  EMAIL = 2,
  SMS = 4,
  HOOK = 8,
  MQTT_ALERT = 16,
  COMMAND = 32,
}

export const deliveryMethodObj: Record<string, any> = {
  "Push Notification": DELIVERY_METHOD.PUSH_NOTIFICATION,
  Email: DELIVERY_METHOD.EMAIL,
  SMS: DELIVERY_METHOD.SMS,
  "Web Hook": DELIVERY_METHOD.HOOK,
  "Web Alert": DELIVERY_METHOD.MQTT_ALERT,
  Command: DELIVERY_METHOD.COMMAND,
};

export const allRoleJSONPathsJson: Record<string, any> = {
  vh: "Vehicle Management",
  "vh.m": "Model Management",
  "vh.m.p": "Model Permission",
  db: "Dashboard",
  "db.p": "Dashboard Permission",
  "db.op": "Features",
  "db.info": "Vehicle Information",
  "db.fs": "Fault Summary",
  "db.raf": "Recent Active Faults",
  "db.rtmv": "Real-Time Map View of Vehicle",
  "db.f": "Fault (Last 7 Days)",
  "db.t": "Help & Support",
  "db.n": "Notifications",
  "db.fi": "-",
  "vh.l": "Vehicle List",
  "vh.l.p": "Permission",
  "vh.l.op": "Features",
  "vh.l.d": "Details",
  "vh.l.d.p": "Permission",
  // "vh.l.d": "Features",
  "vh.l.d.ov": "Overview",
  "vh.l.d.ov.oai": "Meta Data",
  "vh.l.d.ov.m": "Map",
  "vh.l.d.ov.rt": "Recent trips",
  "vh.l.d.ov.s": "Vehicle Statistics",
  "vh.l.d.ov.f": "Fault (Last 7 Days)",
  "vh.l.d.ov.n": "Notifications",
  "vh.l.d.ta": "Trip Analysis",
  "vh.l.d.ta.p": "Permission",
  "vh.l.d.n": "Notifications",
  "vh.l.d.n.p": "Permission",
  "vh.l.d.cmd": "Commands",
  "vh.l.d.cmd.p": "Permission",
  "vh.l.d.f": "Fault and Alerts",
  "vh.l.d.f.p": "Permission",
  "vh.l.d.a": "Analysis",
  "vh.l.d.a.p": "Permission",
  "vh.l.d.attr": "Attributes",
  "vh.l.d.attr.p": "Permission",
  c: "Component Management",
  "c.p": "Permission",
  BMS: "Battery Management",
  "BMS.m": "Model Management",
  "BMS.m.p": "Permission",
  "BMS.db": "Dashboard",
  "BMS.db.p": "Permission",
  // "BMS.db": "Features",
  "BMS.db.map": "Map",
  "BMS.db.af": "Active Faults",
  "BMS.db.bs": "Battery States",
  "BMS.db.s": "Statistics",
  "BMS.db.asoc": "Analysis by SOC",
  "BMS.db.n": "Notifications",
  "BMS.l": "List",
  "BMS.l.p": "Permission",
  // "BMS.l": "Features",
  "BMS.l.d": "Details",
  "BMS.l.d.p": "Permission",
  // "BMS.l.d": "Features",
  "BMS.l.d.ov": "Overview",
  "BMS.l.d.ov.oai": "Meta Data",
  "BMS.l.d.ov.s": "Live Section",
  "BMS.l.d.ov.m": "Map",
  "BMS.l.d.ov.bp": "Battery Pack",
  "BMS.l.d.ov.rbsc": "Recent Battery State Cycle",
  "BMS.l.d.ov.n": "Notifications",
  "BMS.l.d.ov.ch": "Cell History",
  "BMS.l.d.ov.ch.p": "Permission",
  "BMS.l.d.ov.ch.cv": "Cell Voltage",
  "BMS.l.d.ov.ch.ct": "Cell Temprature",
  "BMS.l.d.ca": "Battery State Cycle",
  "BMS.l.d.ca.p": "Permission",
  "BMS.l.d.n": "Notifications",
  "BMS.l.n.p": "Permission",
  "BMS.l.d.cmd": "Commands",
  "BMS.l.cmd.p": "Permission",
  "BMS.l.d.f": "Fault and Alerts",
  "BMS.l.f.p": "Permission",
  "BMS.l.d.a": "Analysis",
  "BMS.l.a.p": "Permission",
  "BMS.l.d.attr": "Attributes",
  "BMS.l.attr.p": "Permission",
  MCU: "Motor Management",
  "MCU.m": "Model Management",
  "MCU.m.p": "Permission",
  "MCU.db": "Dashboard",
  "MCU.db.p": "Permission",
  // "MCU.db": "Features",
  "MCU.db.raf": "Recent Active Faults",
  "MCU.db.f": "Fault (Last 7 Days)",
  "MCU.db.s": "Statistics",
  "MCU.db.n": "Notifications",
  "MCU.l": "List",
  "MCU.l.p": "Permission",
  // "MCU.l": "Features",
  "MCU.l.d": "Details",
  "MCU.l.d.p": "Permission",
  // "MCU.l.d": "Features",
  "MCU.l.d.ov": "Overview",
  "MCU.l.d.ov.oai": "Meta Data",
  "MCU.l.d.ov.m": "Map",
  "MCU.l.d.ov.s": "Live Section",
  "MCU.l.d.ov.f": "Fault (Last 7 Days)",
  "MCU.l.d.ov.n": "Notifications",
  "MCU.l.d.n": "Notifications",
  "MCU.l.d.n.p": "Permission",
  "MCU.l.d.cmd": "Commands",
  "MCU.l.d.cmd.p": "Permission",
  "MCU.l.d.f": "Fault and Alerts",
  "MCU.l.d.f.p": "Permission",
  "MCU.l.d.a": "Analysis",
  "MCU.l.d.a.p": "Permission",
  "MCU.l.d.attr": "Attributes",
  "MCU.l.d.attr.p": "Permission",
  VCU: "Vehicle Controller Management",
  "VCU.m": "Model Management",
  "VCU.m.p": "Permission",
  "VCU.l": "List",
  "VCU.l.p": "Permission",
  ac: "Access Control",
  "ac.l": "List",
  "ac.l.p": "Permission",
  // "ac.l": "Features",
  "c.b": "BMS",
  "c.m": "MCU",
  "c.v": "VCU",
  a: "Analysis",
  "a.p": "Permission",
  // "a": "Features",
  "a.bt": "BMS Tab",
  "a.mt": "MCU Tab",
  "a.vt": "VCU Tab",
  ce: "Carbon Emission",
  sc: "Service Center",
  "sc.l": "List",
  "sc.l.p": "Permission",
  "sc.db": "Dashboard",
  "sc.db.p": "Permission",
  // "sc.db": "Features",
  // "sc.db.cs": "cs",
  su: "User Management",
  "su.p": "Permission",
  fleet: "Fleet",
  "fleet.l": "List",
  "fleet.l.p": "Permission",
  "fleet.db": "Dashboard",
  "fleet.db.p": "Permission",
  // "fleet.db": "Features",
  "fleet.db.map": "Map",
  "fleet.driver": "Driver",
  "fleet.driver.p": "Permission",
  eo: "EV Owner",
  "eo.l": "List",
  "eo.l.p": "Permission",
  "eo.db": "Dashboard",
  "eo.db.p": "Permission",
  // "eo.db.op": "Features",
  "eo.db.oai": "Meta Data",
  "eo.db.rt": "Trip Details",
  "eo.db.f": "Faults",
  // "eo.db.dmd": "Drive mode distance",
  rule: "Rule Management",
  "rule.l": "List",
  "rule.l.p": "Permission",
  // "rule.tr.op": "Features",
  "rule.l.dm": "Delivery Method",
  gr: "Geofencing",
  "gr.p": "Permission",
  "rule.h": "History",
  "rule.h.p": "Permission",
  s: "Settings",
  "s.p": "Permission",
  mp: "My Profile",
  "mp.p": "Permission",
  // "mp": "Features",
  "mp.d": "Profile Details",
  "mp.d.p": "Permission",
  "mp.cp": "Change Password",
  "mp.cp.p": "Permission",
  "mp.s": "Setting",
  "mp.s.p": "Permission",
  hl: "Hardware Logs",
  "hl.p": "Permission",
  ah: "Audit",
  "ah.p": "Permission",
  t: "Help & Support",
  "t.p": "Permission",
  cmd: "Command",
  "cmd.h": "History",
  "cmd.h.p": "Permission",
  // "cmd": "Features",
  "cmd.sc": "Send Command",
  itcu: "Product",
  "itcu.p": "Permission",
  FOTA: "FOTA",
  "FOTA.p": "Permission",
  // "FOTA": "Features",
  "FOTA.ota": "OTA",
  "FOTA.h": "History",
  "FOTA.h.p": "Permission",
  "su.RP": "Role & Permission",
  "su.RP.p": "Permission",
  "su.DG": "Device Group",
  "su.DG.EV": "EV",
  "su.DG.BMS": "BMS",
  "su.DG.MCU": "MCU",
  "su.DG.VCU": "VCU",
};

export const routeNames: Record<string, any> = {
  "/access-management": "Access Management",
  "/command-master": "Command Management",
  "/dashboard": "Dashboard",
  "/audit": "Audit",
  "/employees": "Employee Management",
  "/404": "404",
  "/403": "403",
  "/fault-management": "Fault Management",
  "feedback-category": "Feedback Category",
  "/firmware-management": "Firmware Management",
  "/logger-device": "Logger Device",
  "/login-history": "Login History",
  "/oem-management": "OEM Management",
  "/profile": "My Profile",
  "/role": "Role Permission",
  "/rule-management": "Rule Management",
  "/settings": "Setting Management",
  "/sim": "SIM Card Management",
  "/tickets": "Tickets",
  "/user-management": "User Management",
  "/write-role": "Role",
  "/carbon-emission": "Carbon Emission",
};
